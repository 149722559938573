<script>
import CardColorBg from "@/components/CardColorBg.vue";
import DarkBlueCardItem from "@/components/DarkBlueCardItem.vue";
import BtnYellow from "@/components/BtnYellow.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
  name: 'BlockFour',
  components: {YellowAncorLink, BtnYellow, DarkBlueCardItem, CardColorBg},
  data() {
    return {
      cards: [
        {
          id: 1, title: 'Гражданство РФ',
          text: 'Регистрация на территории Российской Федерации',
          img: require('@/assets/img/flag.png'),
          class: 'card-sky',
          col: 'col-12 col-md-4'
        },
        {
          id: 2, title: 'Возраст от 18 до 65 лет',
          text: 'Для оформления нужен только паспорт',
          img: require('@/assets/img/pasport.png'),
          class: 'card-yellow',
          col: 'col-12 col-md-4'
        },
        {
          id: 3, title: 'Постоянный доход',
          text: 'Необязательно белый',
          img: require('@/assets/img/many.png'),
          class: 'card-green',
          col: 'col-12 col-md-4'
        },
      ]
    }
  }
}
</script>

<template>
  <div class="row mt-5 gradient" id="blockFour">
    <div class="col-12">
      <h1>Условия получения займа</h1>
    </div>
  </div>
  <div class="row mt-5">
      <card-color-bg v-for="card in cards" :card="card"></card-color-bg>
  </div>
  <div class="row justify-content-center mt-3 mt-md-5">
    <div class="col-12 col-md-2">
      <yellow-ancor-link :ancor="'#leadForm'">Оформить заявку</yellow-ancor-link>
    </div>
  </div>
</template>

<style scoped>

</style>
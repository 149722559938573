<script>
import {SwiperSlide} from "swiper/vue";

export default {
  name: 'BtnIcon',
  props: {
    card: {
      require: true,

    }
  },
}
</script>

<template>
    <div class="col-4 p-1 col-md-auto">
      <div class="card-black p-2 h-100">
        <a href="#leadForm">
          <div class="card-body">
            <div class="row ">
              <div class="col-12 d-flex justify-content-center align-items-center" style="height: 30px;" v-html="card.icon">
              </div>
              <div class="col-12 text-center">
                {{ card.text }}
              </div>
            </div>
          </div>
        </a>

      </div>
    </div>

</template>

<style scoped>
.card-black{
  border-radius: 16px;
  background: #000;
  box-shadow: 0px 4px 12px 0px rgba(35, 69, 129, 0.05);
  font-size: 14px;
  color: #818181;
  
}

.card-black:hover{
  background: #FED305;
  color: #000;
  cursor: pointer;
}

a{
  text-decoration: none;
  color: #fff;
}


</style>




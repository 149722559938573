<script>
import CardGrey from "@/components/CardGrey.vue";
import CardColumn from "@/components/CardColumn.vue";

export default {
  name: 'BlockSeven',
  components: {CardColumn, CardGrey},
  data(){
    return{
      cards:[
        {id: 1, title:'Не перезваниваем и не требуем фото с паспортом', text:'Достаточно анкеты'},
        {id: 2, title:'Не проверяем вашу кредитную историю', text:'Работаем на доверии'},
        {id: 3, title:'Не беспокоим ваших родных и коллег', text:'Мы доверяем вам и не просим справки с работы, не звоним родственникам и коллегам'},
        {id: 4, title:'Ваши данные защищены', text:'Ваши персональные данные и данные вашей банковской карты никогда не перейдут третьим лицам'},
      ]
    }
  }
}
</script>

<template>
  <div class="row mt-5 mb-5 gradient" id="blockSeven">
    <div class="col-12">
      <h1>5 миллионов человек выбрали нас. Доверьтесь и Вы!</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-7">
      <img src="@/assets/img/map.png" alt="" class="img-fluid">
    </div>
    <div class="col-12 col-md-5 mt-4 mt-md-0">
      <div class="card card-grey p-2 p-md-3">
        <div class="card-body">
          <card-grey v-for="card in cards" :card="card"></card-grey>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-grey{
  background: #25282A;
  border: none;
  border-radius: 20px;
}
</style>
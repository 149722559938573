<script>
import BlueCard from "@/components/BlueCard.vue";
import RangeCard from "@/components/RangeCard.vue";
import LeadForm from "@/components/LeadForm.vue";

export default {
  name:'BlockEight',
  components: {LeadForm, RangeCard, BlueCard},

}
</script>

<template>
  <div class="row position-relative pb-5 mt-5 pt-5" id="leadForm2">
    <img src="@/assets/img/bee-bottom.png" class="bee-fon d-none d-md-block" alt="">
    <img src="@/assets/img/bee-bottom-mob.png" class="bee-fon d-block d-md-none" alt="">
    <div class="col-12 col-md-10">
      <blue-card>
        <div class="row">
          <div class="col-12 card-title d-none d-md-block">
            Получите +10% к одобрению, заполнив первый шаг
          </div>
          <div class="col-12 mt-3 d-block d-md-none">
            Оформите заявку прямо сейчас<br> и получите ответ в
            <span class="text-bold header-time">15:10</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <range-card></range-card>
          </div>
          <div class="col-12 col-md-7">
            <lead-form></lead-form>
          </div>
        </div>
      </blue-card>
    </div>
  </div>
</template>

<style scoped>
.bee-fon {
  position: absolute;
  right: 0px;
  width: 576px;
  top: -12px;
}

@media (max-width: 767px) {

  .bee-fon {
    position: absolute;
    left: 0px;
    width: 120px;
    top: -10px;
  }
}
</style>
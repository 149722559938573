<script>
export default {
  name: 'CardColumn',
  props: {
    card: {
      require: true,

    }
  },
}
</script>

<template>
<div class="col-6 col-md-3 mt-4 mt-md-0">
  <div class="row flex-column p-md-3">
    <div class="col-12 d-flex justify-content-center">
      <img :src="card.img" alt="" class="img">
    </div>
    <div class="col-12 p-1 title text-center mt-3">
      {{ card.title }}
    </div>
    <div class="col-12 text text-center mt-3">
      {{ card.text }}
    </div>
  </div>
</div>

</template>

<style scoped>
.title{
  font-size: 26px;
}

@media (max-width: 767px) {
  img{
    max-width: 65px;
  }

  .title{
    font-size: 20px;
    line-height: 22px;
  }

  .text{
    font-size: 16px;
    line-height: 18px;
  }
}

</style>
<script>

import {defineComponent} from "vue";
import RangeCard from "@/components/RangeCard.vue";
import InfoCard from "@/components/InfoCard.vue";
import LeadForm from "@/components/LeadForm.vue";
import BlueCard from "@/components/BlueCard.vue";


export default defineComponent({
  name: 'BlockOne',
  components: {
    BlueCard,
    LeadForm,
    InfoCard,
    RangeCard
  },
  methods: {
    changeTime() {
      let h_time = document.getElementsByClassName("header-time");

      for(let h of h_time){
        if (h != null) {
          let oldDate = new Date();
          let date = new Date(oldDate.getTime() + 7 * 60000);
          let mins = date.getMinutes();
          let hrs = date.getHours();
          if (mins <= 9) {
            mins = '0' + String(mins);
          }

          if (hrs <= 9) {
            hrs = '0' + String(hrs);
          }
          h.innerHTML = `${hrs}:${mins}`;
        }
      }

    },
    getId(prefix, id) {
      return prefix + '_' + id;
    },
  },

  data(){
    return{

    }
  },
  mounted() {
    this.changeTime()
  }
})
</script>

<template>
  <div class="row pb-5">
    <div class="col-12 col-md-6 d-flex align-items-center" id="leadForm" ref="leadForm1">
      <div class="row">
        <div class="col-12">
          <info-card>0% первый займ</info-card>
        </div>
        <div class="col-12 mt-3"><h1 class="banner-title">Моментальные онлайн займы до 100 000 рублей</h1></div>
        <div class="col-12 mt-3 d-none d-md-block">
          Оформите заявку прямо сейчас<br> и получите ответ в
          <span class="text-bold header-time" id="header-time">15:10</span>
          <i class="ps-2 bi bi-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row position-relative pb-5">
    <img src="@/assets/img/bee.png" class="bee-fon d-none d-md-block" alt="">
    <img src="@/assets/img/bee-mob.png" class="bee-fon d-block d-md-none" alt="">
    <div class="col-12 col-md-10">
      <blue-card>
        <div class="row">
          <div class="col-12 card-title d-none d-md-block">
            Получите +10% к одобрению, заполнив первый шаг
          </div>
          <div class="col-12 mt-3 d-block d-md-none">
            Оформите заявку прямо сейчас<br> и получите ответ в
            <span class="text-bold header-time">15:10</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <range-card></range-card>
          </div>
          <div class="col-12 col-md-7">
            <lead-form></lead-form>
          </div>
        </div>
      </blue-card>
    </div>
  </div>

</template>

<style scoped>
.banner-title {
  font-size: 46px;
}

.bee-fon {
  position: absolute;
  right: 39px;
  width: 500px;
  top: -345px;
  z-index: -2;
}

@media (max-width: 767px) {
  .banner-title {
    font-size: 29px;
  }

  .bee-fon {
    position: absolute;
    right: 0px;
    width: 120px;
    top: -68px;
    z-index: -2;
  }
}

</style>
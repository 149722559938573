<script>
export default {
  name: 'CardPay',
  props:{
    card: {
      require: true,

    }
  },
}

</script>

<template>
    <div class="card gradient-card p-md-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12 title text-center text-md-start" v-html="card.title" >

          </div>
          <div class="col-12 mt-3 d-flex justify-content-md-end justify-content-center">
            <img :src="card.img" alt="">
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.gradient-card{
  border-radius: 20px;
  border: 1px solid #2D2E2E;
  background: linear-gradient(161deg, #03020B 41.7%, #4D89FF 100%);
  color: #fff;
}

img{
  max-width: 95px;
}

@media (max-width: 767px) {
  .title{
    font-size: 14px;
    line-height: 16px;
  }

  img{
    max-width: 70px;
  }

  .gradient-card{
    height: 153px;
  }
}
</style>
<script>
export default {
  name: 'BlackCard',
}
</script>

<template>
<div class="info text-center">
<slot></slot>
</div>
</template>

<style scoped>
.info{
  border-radius: 20px;
  background: #000;
  color: #FFF;
  display: inline-block;
  padding: 3px 10px;
}
</style>
<script>
export default {
  name: 'InfoCard',
}
</script>

<template>
<div class="info text-center">
<slot></slot>
</div>
</template>

<style scoped>
.info{
  border-radius: 20px;
  background: #1B2037;
  color: #FFE161;
  display: inline-block;
  padding: 2px 10px;
}
</style>
<script>
import DarkBlueCardItem from "@/components/DarkBlueCardItem.vue";
import LeftMenuItem from "@/components/LeftMenuItem.vue";
import BtnYellow from "@/components/BtnYellow.vue";
import leadForm from "@/components/LeadForm.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
  name: 'BlockTwo',
  computed: {
    leadForm() {
      return leadForm
    }
  },
  components: {YellowAncorLink, BtnYellow, LeftMenuItem, DarkBlueCardItem},
  methods:{

  },
  data() {
    return {
      cards: [
        {
          id: 1, title: 'Выберите сумму и срок займа',
          text: 'Оформите заявку на займ вне зависимости от вашего местоположения, главное иметь интернет, паспорт и банковскую карту',
          img: require('@/assets/img/card1.png'),
          col: 'col-12 col-md-7 mt-3',
          mobile: {
            bgClass: 'background-repeat: no-repeat; background-position: right bottom; background-size: auto',
            textClass: 'col-9'
          }
        },
        {
          id: 2, title: 'Заполните данные для получения денег',
          text: 'Расскажите немного о себе — откуда Вы, какой займ Вам нужен',
          img: require('@/assets/img/card2.png'),
          col: 'col-6 col-md-5 mt-3',
          mobile: {
            bgClass: 'background-repeat: no-repeat; background-position: right bottom; background-size: contain',
            textClass: 'col-12'
          }
        },
        {
          id: 3, title: 'Дождитесь одобрения сервиса',
          text: 'Решение по вашей заявке будет принято в течение нескольких секунд – не закрывайте сайт!',
          img: require('@/assets/img/card3.png'),
          col: 'col-6 col-md-5 mt-3',
          mobile: {
            bgClass: 'background-repeat: no-repeat; background-position: right bottom; background-size: contain',
            textClass: 'col-12'
          }
        },
        {
          id: 4, title: 'Получите деньги',
          text: 'Перечисление на карту или любым удобным способом',
          img: require('@/assets/img/card4.png'),
          col: 'col-12 col-md-7  mt-3',
          mobile: {
            bgClass: 'background-repeat: no-repeat; background-position: right bottom; background-size: auto',
            textClass: 'col-9'
          }
        },
      ]
    }
  }

}
</script>

<template>
  <div class="row line" id="blockTwo">
    <div class="col-12 gradient  ps-md-5 pt-5">
      <h1>Как работает сервис?</h1>
    </div>
  </div>
  <div class="row mt-3 ps-md-5">
    <dark-blue-card-item class="mt-3" v-for="card in cards" :card="card"></dark-blue-card-item>
  </div>
  <div class="row justify-content-center mt-3 mt-md-5">
    <div class="col-12 col-md-2">
      <yellow-ancor-link :ancor="'#leadForm'">Оформить заявку</yellow-ancor-link>

    </div>
  </div>

</template>

<style scoped>
.gradient {
  background: linear-gradient(#010101, #111212);
}

.line {
  border-top: 1px solid #212121;
}
</style>
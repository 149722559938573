<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: 'MobileMenu',
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
      if(this.isOpen){
        this.menuBurger = 'bi bi-x-lg';
      }else {
        this.menuBurger = 'bi bi-list';
      }
    },
    handleClick() {
      this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }
  },
  data(){
    return{
      isOpen: false,
      menuBurger: 'bi bi-list',
      ref: null
    }
  }
});
</script>

<template>
  <div class="row mt-3 d-flex d-md-none justify-content-between">
    <div class="col-5">
      <img src="@/assets/logo.png" alt="" class="img-fluid">
    </div>
    <div class="col-2" @click="toggleMenu">
      <i :class="this.menuBurger"></i>
    </div>
  </div>
  <div class="row" v-if="isOpen">
    <div class="col-12 mt-3">
      Оформить заявку
    </div>
    <div class="col-12 mt-3" @click="handleClick">
      О сервисе
    </div>
  </div>
</template>

<style scoped>
i{
  font-size: 30px;
}
</style>
<script>
export default {
  name: 'CardColorBg',
  props: {
    card: {
      require: true,

    }
  },
}
</script>

<template>
  <div :class="card.col + ' d-none d-md-block'">
    <div :class="card.class">
      <div class="card-body p-3 h-100">
        <div class="row  justify-content-between h-100">
          <div class="col-12 title">
            {{ card.title }}
          </div>
          <div class="col-12 d-flex align-items-center">
            <img :src="card.img" alt="" class="img">
          </div>
          <div class="col-12 text">
            {{ card.text }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div :class="card.col + ' d-block d-md-none mt-3'">
    <div :class="card.class">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-8">
            <p class="title">{{ card.title }}</p>
            <p class="text">{{ card.text }}</p>
          </div>
          <div class="col-4 d-flex align-items-center p-0">
            <img :src="card.img" alt="" class="img img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<style scoped>
.dark-blue-card {
  border-radius: 24px;
  border: 1px solid #2D2E2E;
  background: #03080D;
}

.title {
  font-size: 24px;
  color: #fff;
}

.text {
  font-size: 18px;
  color: #fff;
}

.img{
  max-width: 200px;
}

.card-sky{
  background-image: url("@/assets/img/blue-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 15px;
}

.card-yellow{
  background-image: url("@/assets/img/yellow-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 15px;
}

.card-green{
  background-image: url("@/assets/img/green-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 15px;
}

@media (max-width: 767px) {
  .title {
    font-size: 20px;
  }

  .text{
    font-size: 16px;
  }

  .img{
    max-width: 100%;
  }
}
</style>
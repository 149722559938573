<script >
export default {
  name: 'LeftMenuItem',
  props:{
    item: {
      require: true,

    },
    full:false
  },
}
</script>

<template>
  <div class="col-12 pt-3">
    <div class="row">
      <div class="col-2">
        <img class="icon" :src="item.icon" alt="" @click="this.$emit('toggleMenu')">
      </div>
      <div class="col-10 cursor" v-if="full" @click="this.$emit('closeMenu')">
        <a :href="item.href"> {{ item.text }}</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon{
  width: 30px;
}

a{
  text-decoration: none;
  color: #fff;
}

</style>
<script>
import CardColumn from "@/components/CardColumn.vue";
import CardColorBg from "@/components/CardColorBg.vue";
import BtnYellow from "@/components/BtnYellow.vue";
import YellowAncorLink from "@/components/YellowAncorLink.vue";

export default {
  name: 'BlockSix',
  components: {YellowAncorLink, BtnYellow, CardColorBg, CardColumn},
  data(){
    return{
      cards:[
        {
          id: 1, title: 'Сервис работает мгновенно',
          text: 'Автоматически и без участия человека',
          img: require('@/assets/img/numb-1.png'),
        },
        {
          id: 2, title: 'Мы не стараемся быть банком',
          text: 'Лояльно относимся к кредитной истории или ее отсутствию',
          img: require('@/assets/img/numb-2.png'),
        },
        {
          id: 3, title: 'Деньги 24/7',
          text: 'Сервису не нужен отдых! Он обрабатывает займы круглосуточно и без выходных',
          img: require('@/assets/img/numb-3.png'),
        },
        {
          id: 4, title: 'Приготовьте только паспорт',
          text: 'Сделку проходит дистанционно, ваше личное присутствие не нужно',
          img: require('@/assets/img/numb-4.png'),
        },
      ]
    }
  }
}
</script>

<template>
  <div class="row mt-5 mb-5 gradient" id="blockSix">
    <div class="col-12">
      <h1>Лучший сервис получить деньги быстро</h1>
    </div>
  </div>
  <div class="card black-card">
    <div class="card-body">
      <div class="row">
        <card-column v-for="card in cards" :card="card"></card-column>
      </div>
      <div class="row justify-content-center mt-3 mt-md-5">
        <div class="col-12 col-md-2">
          <yellow-ancor-link :ancor="'#leadForm2'">Оформить заявку</yellow-ancor-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.black-card {
  border: 1px solid #2D2E2E;
  background: #000000;
  border-radius: 24px;
  color: #fff;
}
</style>
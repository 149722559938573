<script>
import {defineComponent} from "vue";
import BtnYellow from "@/components/BtnYellow.vue";
import BlackCard from "@/components/BlackCard.vue";

export default defineComponent({
  name: 'RangeCard',
  components: {BlackCard, BtnYellow},
  methods: {
    changeRange(id) {
      console.log('changeRange');
      let range = document.getElementsByClassName('range_' + id)
      let pr = document.getElementsByClassName('progress_' + id);
console.log(pr)
      for(let i = 0; i < range.length;i++ ){
        let fullWidth = parseInt(range[i].offsetWidth) - 15;
        let steps = range[i].getAttribute('max') - range[i].getAttribute('min');
        let oneWidth = fullWidth / steps;
        let width = oneWidth * (range[i].value - range[i].getAttribute('min'));
        width += 'px';
        pr[i].style.width = width;
        console.log(i)
        console.log(pr[i].style.width)
      }

    },
    getId(prefix, id) {
      return prefix + '_' + id;
    },

    getClass(prefix, id) {
      return prefix + '_' + id;
    },

  },
  mounted() {


  },
  data(){
    return{
      lead: this.lead,
    }
  }

});
</script>

<template>
  <div class="row">
    <div class="col-12 card-text mt-3">
      <div class="row justify-content-between">
        <div class="col-4 col-md-auto">
          ЗАНЯТЬ
        </div>
        <div class="col-4 col-md-auto">
          <black-card class="w-100"><span class="sum" >{{ this.lead.sum}}</span></black-card>
        </div>
      </div>
      <div class="p-2 mt-2 position-relative">
        <input :id="getId('range', 'sum')"
               type="range"
               min="5000"
               max="100000"
               step="1000"
               :class="'slider form-range w-100 ' + getClass('range', 'sum')" data-block=".sum"
               v-model="this.lead.sum"
               @click="changeRange('sum')"
               @input="changeRange('sum')"
               @change="changeRange('sum');">
        <div :id="getId('progress', 'sum')" :class="'progress ' + getClass('progress', 'sum')"></div>
      </div>
      <div class="row text-rang">
        <div class="col-4">
          10000
        </div>
        <div class="col-4 text-center">
          50000
        </div>
        <div class="col-4 text-end">
          100000
        </div>
      </div>
    </div>
    <div class="col-12 card-text mt-3">
      <div class="row justify-content-between">
        <div class="col-4 col-md-auto">
          НА СРОК
        </div>
        <div class="col-4 col-md-auto">
          <black-card class="w-100"><span class="term">{{ this.lead.term}} дней</span></black-card>
        </div>
      </div>
      <div class="p-2 mt-2 position-relative">
        <input :id="getId('range', 'term')"
               type="range"
               min="7"
               max="40"
               step="1"
               v-model="this.lead.term"
               :class="'slider form-range w-100 ' + getClass('range', 'term')"
               data-block=".term"
               @click="changeRange('term')"
               @input="changeRange('term')"
               @change="changeRange('term');">
        <div :id="getId('progress', 'term')" :class="'progress ' + getClass('progress', 'term')"></div>
      </div>
      <div class="row text-rang">
        <div class="col-4">
          7 дней
        </div>
        <div class="col-4 text-center">
          20 дней
        </div>
        <div class="col-4 text-end">
          40 дней
        </div>
      </div>
    </div>
  </div>
  <div class="row card-text pt-3 ps-4 pe-4 pb-0 align-items-center justify-content-end d-none d-md-flex">
    <div class="col-4 col-md-auto">
      <p class="mb-1">Сумма займа</p>
      <p class="mb-1 text-bold sum" >{{this.lead.sum}} ₽</p>
    </div>
    <div class="col-4 col-md-auto">
      <p class="mb-1 text-center">Первый займ</p>
      <p class="mb-1 yellow-text text-bold text-center">0%</p>
    </div>
  </div>
</template>

<style scoped>


.white-bord-card {
  border-radius: 13px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: transparent;
}

.card-title {
  font-size: 32px;
  font-weight: 700;
}

.card-title2 {
  font-size: 20px;
}

.text-rang {
  color: #787878;
  font-size: 14px;
}

.card-text {
  font-size: 16px;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range + .progress {
  background-color: #FED305;
  height: 14px;
  width: calc(50% - 15px);
  position: absolute;
  top: 13px;
}

.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 25px;
  background-color: #fff;
  border-radius: 16px;
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}

input[type=range]::-webkit-slider-runnable-track {
  border-radius: 50px;
  height: 14px;
  border: none;
  background-color: #1070B7;
}

input[type=range]::-webkit-slider-thumb {
  background: #FED305;
  border: 6px solid #fff;
  border-radius: 50px;
  cursor: pointer;
  width: 19px;
  height: 19px;
  -webkit-appearance: none;
  margin-top: -3px;
}

input[type=range]::-moz-range-track {
  border-radius: 20px;
  height: 5px;
  border: none;
  background-color: #fff;
}

input[type=range]::-moz-range-thumb {
  background: #FED305;
  border: 5px solid #fff;
  border-radius: 50px;
  cursor: pointer;
}

</style>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import DarkBlueCardItem from "@/components/DarkBlueCardItem.vue";
import CardPay from "@/components/CardPay.vue";
import {Navigation} from "swiper/modules";
export default {
  name: 'BlockThree',
  components: {
    CardPay,
    DarkBlueCardItem,
    Swiper,
    SwiperSlide,
    Navigation,
  },

  methods:{
    changeRow(){
      let next = document.getElementsByClassName("swiper-button-next");
      let prev = document.getElementsByClassName("swiper-button-prev");
    }
  },
  setup() {
    return {
      Swiper,
      modules: [Navigation]
    };
  },
  data() {
    return {
      navigation: {
        //Next
        nextEl: '.swiper-button-next',//The name of the next label can be customized
        //Previous
        prevEl: '.swiper-button-prev'//The name of the previous label can be customized
      },
      breakpoints: {
        // when window width is >= 320px
        150: {
          slidesPerView: 2.2,
          spaceBetween: 10
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 30
        }
      },
      cards: [
        {
          id: 1, title: 'Карты платежной системы MasterCard',
          img: require('@/assets/img/mastercard.png'),
          col: 'col-6 col-md-2'
        },
        {
          id: 2, title: 'Карты платежной системы VISA',
          img: require('@/assets/img/visa.png'),
          col: 'col-6 col-md-2'
        },
        {
          id: 3, title: 'Карты платежной системы Maestro',
          img: require('@/assets/img/maestro.png'),
          col: 'col-6 col-md-2'
        },
        {
          id: 4, title: 'Карты платежной системы МИР',
          img: require('@/assets/img/mir.jpg'),
          col: 'col-6 col-md-2'
        },
        {
          id: 5, title: 'Система быстрых платежей СБП',
          img: require('@/assets/img/sbp.png'),
          col: 'col-6 col-md-2'
        },
        {
          id: 6, title: 'Электронный кошелек <br> Qiwi',
          img: require('@/assets/img/qiwi.png'),
          col: 'col-6 col-md-2'
        },
        {
          id: 7, title: 'Электронный кошелек Юmoney',
          img: require('@/assets/img/umany.png'),
          col: 'col-6 col-md-2'
        },
      ]
    }
  }
}
</script>

<template>
  <div class="row mt-5 mb-5 gradient justify-content-between" id="blockThree">
    <div class="col-8">
      <h1>Способы получения займа</h1>
    </div>
    <div class="col-4 col-md-2 d-flex">
      <div class="swiper-button-prev  cursor">
      </div>
      <div class="swiper-button-next  cursor">
      </div>
    </div>
  </div>
  <swiper
      :slides-per-view="5"
      :space-between="50"
      :modules="modules"
      :navigation="navigation"
      :loop="true"
      :breakpoints="breakpoints"

  >

      <swiper-slide v-for="card in cards">
        <card-pay  :card="card"></card-pay>
      </swiper-slide>
  </swiper>
</template>

<style scoped>
.swiper-button-prev::after{
  cursor: pointer;
  content: "" !important;
  width: 35px;
  height: 35px;
  background: url(@/assets/img/prev.png);
  background-size: contain;
  display: block;
  margin-right: 15px;
  border-radius: 20px;
  border: 0;
}

.swiper-button-next::after {
  cursor: pointer;
  content: "" !important;
  width: 35px;
  height: 35px;
  background: url(@/assets/img/next.png);
  background-size: contain;
  display: block;
}
</style>

<script>
import LeftMenu from "@/components/LeftMenu.vue";
import ProgressCard from "@/components/RangeCard.vue";
import BlockOne from "@/components/BlockOne.vue";
import BlockTwo from "@/components/BlockTwo.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';

import 'swiper/css';
import BlockThree from "@/components/BlockThree.vue";
import BlockFour from "@/components/BlockFour.vue";
import BlockFive from "@/components/BlockFive.vue";
import BlockSix from "@/components/BlockSix.vue";
import BlockSeven from "@/components/BlockSeven.vue";
import BlockEight from "@/components/BlockEight.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import ServiceBlock from "@/components/ServiceBlock.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import ResultBlock from "@/components/ResultBlock.vue";

export default {
  components: {
    ResultBlock,
    MobileMenu,
    ServiceBlock,
    FooterBlock,
    BlockEight,
    BlockSeven,
    BlockSix,
    BlockFive,
    BlockFour,
    BlockThree, BlockTwo,
    BlockOne,
    ProgressCard,
    LeftMenu,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      lead: this.lead,
      fullMenu: false,
      openMenuClasses: {
        leftSidebar: 'fix-menu-close d-none d-md-block p-0',
        rightSidebar: 'col-12 col-md-96pc pt-md-5'

      }
    }
  },
  methods: {

    getLocalStorage() {
      if (localStorage.getItem('sum') !== null) {
        this.lead.sum = localStorage.getItem('sum');
      }
      if (localStorage.getItem('f') !== null && localStorage.getItem('f') !== undefined) {
        this.lead.person.f = localStorage.getItem('f');
      } else {
        this.lead.person.f = '';
      }
      if (localStorage.getItem('i') !== null && localStorage.getItem('i') !== undefined) {
        this.lead.person.i = localStorage.getItem('i');
      } else {
        this.lead.person.i = '';
      }
      if (localStorage.getItem('o') !== null && localStorage.getItem('o') !== undefined) {
        this.lead.person.o = localStorage.getItem('o');
      } else {
        this.lead.person.o = '';
      }
      if (localStorage.getItem('dr') !== null) {
        this.lead.person.dr = localStorage.getItem('dr');
      } else {
        this.lead.person.dr = '';
      }
      if (localStorage.getItem('tel') !== null) {
        this.lead.person.tel = localStorage.getItem('tel');
      } else {
        this.lead.person.tel = '';
      }
    },
    openMenu() {
      this.openMenuClasses.leftSidebar = 'fix-menu-open d-none d-md-block p-0';
      this.fullMenu = true;
    },
    closeMenu() {
      this.openMenuClasses.leftSidebar = 'fix-menu-close d-none d-md-block p-0';
      this.fullMenu = false;
    }
  },
  mounted() {
    this.getLocalStorage();
  }
}
</script>
<template>
  <div class="container-fluid" v-if=" !this.lead.validateStep1" >
    <div class="row justify-content-end">
      <div :class="openMenuClasses.leftSidebar">
        <left-menu @closeMenu="closeMenu" @openMenu="openMenu" :fullMenu = "this.fullMenu"></left-menu>
      </div>
      <div :class="openMenuClasses.rightSidebar" @click="closeMenu">
        <mobile-menu></mobile-menu>
        <div class="row mt-4 mt-md-0">
          <div class="col-12 ps-md-5" id="pageTop">
            <block-one></block-one>
          </div>
          <div class="col-12 grey-fon">
            <block-two></block-two>
          </div>
          <div class="col-12 grey-fon ps-md-5">
            <block-three></block-three>
            <block-four></block-four>
            <block-five></block-five>
            <block-six></block-six>
            <block-seven></block-seven>
            <block-eight></block-eight>
            <footer-block></footer-block>
            <service-block></service-block>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" v-if="this.lead.validateStep1">
    <result-block></result-block>
    <footer-block></footer-block>
    <service-block></service-block>
  </div>

</template>

<style>
body {
  background-color: #010101;
  color: #fff;
  font-size: 16px;
}
.col-md-96pc{
  width: 96% !important;;
}
.fix-menu-close{
  width:4% !important;
  position:fixed;
  left:0;
  top:0;
  height: 100vh;
  cursor:pointer;
}
.fix-menu-open{
  width:16.6666% !important;
  position:fixed;
  left:0;
  top:0;
  height: 100vh;
  cursor:pointer;
  z-index:9999;
}

.fix-menu-open .menu-logo-block{
  justify-content: center;
}

.fix-menu-close .menu-logo-block{
  justify-content: start;
  padding-left:0;
  margin-top: 15px;
}
.cursor {
  cursor: pointer;
}

.grey-text {
  color: #282828;
}

.text-bold {
  font-weight: 700;
}

.yellow-text {
  color: #FED305;
}

.card-title {
  font-size: 30px;
  font-weight: 700;
}

.card-title2 {
  font-size: 18px;
}

.card-text {
  font-size: 15px;
}


.grey-fon {
  background: #111212;
}

@media (max-width: 767px) {
  h1 {
    font-size: calc(1.375rem + 0.4vw);
  }
}

.card-black .svg-icon path {
  stroke: white;
  fill: none;
  /*  fill:white;
    color:white;*/
}

.card-black:hover .svg-icon path {
  stroke: black;
  fill: none;
  /*  fill:black;
    color:black;*/
}

</style>

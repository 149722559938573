<script >
export default {
  name: 'YellowAncorLink',
  props:{
    ancor: {
      type: String
    }
  }
}
</script>

<template>
  <div>
      <a class="btn yellow-btn w-100 cursor" :href="ancor">
        <slot></slot>
      </a>
  </div>
</template>

<style scoped>

.yellow-btn{
  border-radius: 12px;
  border: 1px solid rgba(80, 80, 80, 0.30);
  background: #FED305;
}
</style>
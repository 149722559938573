<script>
import BtnYellow from "@/components/BtnYellow.vue";

export default {
  name: 'FooterBlock',
  components: {BtnYellow},
  methods:{

  },
  data(){
    return{
      show: false,
    }
  }
}
</script>

<template>
<footer>
  <div class="row pt-5 mt-5 bord">
    <div class="col-12 col-md-3">
      <p class="text-grey">Телефон горячей линии</p>
      <p class="text-bold">+7 (343) 300-92-39</p>
    </div>
    <div class="col-12 col-md-3">
      <p class="text-grey">Время работы</p>
      <p class="text-bold">10:00 - 19:00 МСК</p>
    </div>
    <div class="col-12 col-md-3">
      <p class="text-grey">E-mail</p>
      <p class="text-bold">work-zaim@yandex.ru</p>
    </div>
    <div class="col-12 col-md-3">
      <p class="text-grey">Почтовый адрес</p>
      <p class="text-bold">г. Екатеринбург ул.Шейкмана 9</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-auto">
      <img src="@/assets/img/18.png" alt="">
    </div>
    <div class="col-10 col-md-3 footer-smtext">
      Сервис предназначен для лиц старше 18 лет
    </div>
    <div class="col-12 col-md-5 mt-3 mt-md-0">
      <div class="row">
        <div class="col-2 col-md-1">
          <img src="@/assets/img/mastercard.png" alt="" class="img-fluid">
        </div>
        <div class="col-2 col-md-1">
          <img src="@/assets/img/visa.png" alt="" class="img-fluid">
        </div>
        <div class="col-2 col-md-1">
          <img src="@/assets/img/maestro.png" alt="" class="img-fluid">
        </div>
        <div class="col-2 col-md-1">
          <img src="@/assets/img/mir.jpg" alt="" class="img-fluid">
        </div>
        <div class="col-2 col-md-1">
          <img src="@/assets/img/qiwi.png" alt="" class="img-fluid">
        </div>
        <div class="col-2 col-md-1">
          <img src="@/assets/img/umany.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  <p class="text-white mt-4">
    * У пользователей сервиса есть возможность получить займ с минимальной процентной ставкой одобренной МФО.
    Подробности при выборе персонального предложения.Займ у партнеров выдается в российских рублях гражданам Российской Федерации,
    на банковский счет, на карту или наличными. Минимальная сумма займа: 1 000 рублей. Максимальная сумма займа: 100 000 рублей.
    Процентная ставка и срок займа: по решению МФО.
  </p>
  <div class="row mt-5 mb-5">
    <div class="col-10 col-md-6">
      Перечень кредитных организаций осуществляющих предоставление займов:
    </div>
    <div class="col-2 col-md-1">
      <span class="row-down" @click="show=!show"><i class="bi bi-chevron-down"></i></span>
    </div>
    <div class="col-12 mt-4" v-if="show">
      <ul>
        <li class="mt-2">ООО МКК "ПЛИСКОВ", 410052, г. Саратов, просп. им. 50 Лет Октября, дом 132А, офис 33, Лицензия № 1903363009056, ИНН 6453158170, ОГРН 1186451026288, процентная ставка от 0,1 % в день</li>
        <li class="mt-2">ООО МКК "Кредиттер", 115280, г. Москва, ул. Ленинская слобода, д. 19, стр. 6, ком. 7, Лицензия № 1903045009373, ИНН 7702463482, ОГРН 1197746100530, процентная ставка от 0,99 % в день</li>
        <li class="mt-2">ООО "МКК КАНГАРИЯ", 344101, г. Ростов-на-Дону, ул. Ленинградская, д. 7, пом. № П-13, Лицензия № 1904067009295, ИНН 9201526872, ОГРН 1189204008564, процентная ставка от 0,99 % в день</li>
        <li class="mt-2">ООО МФК "ДЗП-Центр", 190031, г. Санкт-Петербург, пер. Спасский, д. 14/35, лит. А, пом. 38 Н, Лицензия № 651403140005467, ИНН 7838500558, ОГРН 1147847029990, процентная ставка от 0 % в день</li>
        <li class="mt-2">ООО МФК "ЦФП", 117218, г. Москва, ул. Кржижановского, д. 29, корп. 5, пом. I, ком. 10, Лицензия № 2110177000840, ИНН 7733783309, ОГРН 1117746890645, процентная ставка от 0 % в день</li>
      </ul>
    </div>
  </div>
</footer>
</template>

<style scoped>
.bord{
  border-top: 1px solid #212121;
}

.text-grey{
  color: #787878;
}

.footer-smtext{
  font-size: 14px;
}

.row-down{
  border-radius: 12px;
  border: 1px solid rgba(80, 80, 80, 0.30);
  background: #FED305;
  color: #000;
  padding: 5px 15px;
  align-items: center;
}
</style>
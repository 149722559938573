<script>
export default {
  name: 'CardGrey',
  props: {
    card: {
      require: true,

    }
  },
}
</script>

<template>
      <div class="row">
        <div class="col-auto">
          <img src="@/assets/img/ok.png" alt="">
        </div>
        <div class="col-10">
          <p class="title">{{card.title}}</p>
          <p class="text">{{card.text}}</p>
        </div>
      </div>
</template>

<style scoped>
.title{
  color: #FED305;
  font-size: 26px;
}

.text{
  font-size: 18px;
  color: #fff;
}

@media (max-width: 767px) {
  .title{
    font-size: 20px;
    line-height: 22px;
  }

  .text{
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
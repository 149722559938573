<script >
export default {
  name: 'DarkBlueCardItem',
  props:{
    card: {
      require: true,

    }
  },
}
</script>

<template>
  <div :class="card.col + ' d-none d-md-block'">
    <div class="card dark-blue-card h-100 p-3 p-md-2">
      <div class="card-body">
        <div class="row">
          <div class="col-7">
            <p class="title">{{card.title}}</p>
            <p class="text">{{card.text}}</p>
          </div>
          <div class="col-5 d-flex align-items-center">
            <img :src="card.img" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div :class="card.col + ' d-block d-md-none'">
    <div class="card dark-blue-card h-100">
      <div class="card-body" :style="'background-image: url(' + card.img + ');' + card.mobile.bgClass">
        <div class="row">
          <div class="col-12">
            <p class="title">{{card.title}}</p>
          </div>
          <div  :class="card.mobile.textClass">
            <p class="text">{{card.text}}</p>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.dark-blue-card{
  border-radius: 24px;
  border: 1px solid #2D2E2E;
  background-color: #03080D;
  background-image: url("@/assets/img/ellipse.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.title{
  font-size: 22px;
  color: #fff;
}

.text{
  font-size: 16px;
  color: #C4C4C4;
}
@media (max-width: 767px) {
  .title{
    font-size: 20px;
    line-height: 20px;
  }

  .text{
    font-size: 16px;
    line-height: 18px;
  }

  .dark-blue-card{
    background-position: right bottom;
  }
}

</style>
<script >
import LeftMenuItem from "@/components/LeftMenuItem.vue";
import BtnYellow from "@/components/BtnYellow.vue";

export default {
  name: 'LeftMenu',
  components: {BtnYellow, LeftMenuItem},
  data(){
    return{
      items:[
        {id:1, href:'#service', icon:require('@/assets/img/icon1.png'), text:'О сервисе'},
        {id:2, href:'#blockThree', icon:require('@/assets/img/icon2.png'), text:'Способы полученния'},
        {id:3, href:'#blockFour', icon:require('@/assets/img/icon3.png'), text:'Условия'},
        {id:4, href:'#blockFive', icon:require('@/assets/img/icon4.png'), text:'На что можете занять'},
        {id:5, href:'#blockSix', icon:require('@/assets/img/icon5.png'), text:'Почему мы?'},
        {id:6, href:'#blockSeven', icon:require('@/assets/img/icon6.png'), text:'Нам доверяют'},
        {id:7, href:'#service', icon:require('@/assets/img/icon7.png'), text:'Информация'},
      ],
    }


  },
  props:{
    fullMenu:{
      type: Boolean
    }
  },
  methods:{
    openMenu(){
      this.$emit('openMenu')
    },
    closeMenu(){
      this.$emit('closeMenu')
    },
    toggleMenu(){
      if(this.fullMenu){
        this.$emit('closeMenu')
      }else{
        this.$emit('openMenu')
      }
    },
    scrollTop(){
      window.scrollTo(0, 0);
    }
  }
}
</script>
<template>
<div class="left-gradient h-100 position-relative">
  <div class="row m-0 fix-menu">
    <div class="col-12 d-flex menu-logo-block" @click="scrollTop">
      <img src="@/assets/logo.png" alt="" v-if="fullMenu">
      <img src="@/assets/logo-sm.png" alt="" v-if="!fullMenu">
    </div>
    <div class="col-12 ps-4 pe-4">
      <hr class="grey-text">
    </div>
    <left-menu-item v-for="item in items" :item="item" :full="fullMenu" @toggleMenu="toggleMenu" @openMenu="openMenu" @closeMenu="closeMenu"></left-menu-item>
    <a href="#leadForm">
      <btn-yellow class="mt-5" v-if="fullMenu">Оформить заявку</btn-yellow>
    </a>
  </div>
</div>
</template>

<style scoped>
.left-gradient{
  background: linear-gradient(90deg, #070707 0%, #1D1F1F 100%);
}
.fix-menu{
  position: fixed;
  width: 16.666%;
  top:0;
  left:0;
}

a:hover{
  color: #fff;
}
</style>
<script>

export default {
  name: 'ServiceBlock',
  components:{

  },

  setup(){

  },
  data(){
    return{

    }
  }
}
</script>

<template>
  <div  id="service">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item me-3" role="presentation">
        <button class="nav-link active"
                id="pills-service-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-service"
                type="button"
                role="tab"
                aria-controls="pills-service"
                aria-selected="true">О сервисе
        </button>
      </li>
      <li class="nav-item me-3" role="presentation">
        <button class="nav-link"
                id="pills-safety-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-safety"
                type="button"
                role="tab"
                aria-controls="pills-safety"
                aria-selected="false">Безопасность
        </button>
      </li>
      <li class="nav-item me-3 mt-3 mt-md-0" role="presentation">
        <button class="nav-link"
                id="pills-doc-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-doc"
                type="button"
                role="tab"
                aria-controls="pills-doc"
                aria-selected="false">Документы
        </button>
      </li>
      <li class="nav-item mt-3 mt-md-0" role="presentation">
        <button class="nav-link"
                id="pills-partnership-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-partnership"
                type="button"
                role="tab"
                aria-controls="pills-partnership"
                aria-selected="false">Сторудничество
        </button>
      </li>
    </ul>
    <div class="tab-content  pb-5" id="pills-tabContent">
      <div class="tab-pane fade show active"
           id="pills-service"
           role="tabpanel"
           aria-labelledby="pills-service-tab"
           tabindex="0">
        <h3 class="mt-4 mb-4">О сервисе</h3>
        <p>Сервис оказывает абсолютно бесплатные услуги по подбору кредитных продуктов для клиентов, а именно предлагает клиенту список предложений кредитных
          учреждений, и прочих финансовых организаций, в которые клиент может обратиться с целью оформления заявки на кредитный продукт.
        </p>
        <p>
          Сервис не является банком или кредитором, не относится к финансовым учреждениям и не несёт ответственности за последствия любых заключенных договоров
          кредитования или условия по ним.
        </p>
        <p>
          Персональные данные защищены. Вся указанная информация находится в безопасности. Мы не передаём персональные данные третьим лицам. Никто кроме вас
          не имеет доступ к информации которую вы заполнили на сайте.
        </p>
        <p>
          Услуги на проекте оказываются ИП Горшенина А.А. ОГРНИП 320665800078661, ИНН 662341773168, Оператор персональных данных 66-20-006644 от 21.08.2021г.
          Фактический адрес: 620014, Свердловская область, город Екатеринбург, улица Шейнкмана , дом 9.
        </p>
      </div>
      <div class="tab-pane fade"
           id="pills-safety"
           role="tabpanel"
           aria-labelledby="pills-safety-tab"
           tabindex="0">
        <h5 class="text-bold mt-5">Оператор персональных данных</h5>
        <p>Регистрация в РКН №72-21-005913 Приказ № 145 от 13.08.2021</p>

        <h5 class="text-bold">SSL сертификат</h5>
        <p>Сертификат обеспечивает защиту ваших данных, безопасность платежей и предотвращает несанкционированный доступ к информации</p>

        <h5 class="text-bold">Все прозрачно</h5>
        <p>Мы ведем прозрачную политику, вся информация о стоимости и условиях открыто представлена на сайте</p>

        <h5 class="text-bold">Ваши данные защищены</h5>
        <p>Ваши персональные данные и данные вашей банковской карты никогда не перейдут третьим лицам</p>

        <h5 class="text-bold">Не перезваниваем и не требуем фото с паспортом</h5>
        <p>Достаточно анкеты</p>

        <h5 class="text-bold">Не беспокоим ваших родных и коллег</h5>
        <p>Мы доверяем вам и не просим справки с работы, не звоним родственникам и коллегам</p>
      </div>
      <div class="tab-pane fade"
           id="pills-doc"
           role="tabpanel"
           aria-labelledby="pills-doc-tab"
           tabindex="0">
        <p class="text-bold mt-5">Заполняя анкету на нашем сервисе Вы соглашаетесь с условиями и соглашениями указанными на сайте</p>
        <ul>
          <li><a target="_blank" href="./politic-obrab-dan.pdf">Политика обработки персональных данных</a></li>
          <li><a target="_blank" href="./pologenie_ob_obrab.pdf">Положение об обработке персональных данных</a></li>
          <li><a target="_blank" href="./sogl_obrab.pdf">Согласие на обработку персональных данных</a></li>
          <li><a target="_blank" href="./sogl_phone_email.pdf">Согласие на звонки, E-mail рассылки с доменов партнеров сервиса</a></li>
        </ul>
      </div>
      <div class="tab-pane fade"
           id="pills-partnership"
           role="tabpanel"
           aria-labelledby="pills-partnership-tab"
           tabindex="0">
        <div class="row mt-5">
          <div class="col-auto">
            <a href="tg://resolve?domain=nik_gor86" class="cursor"><img src="@/assets/img/telegram.png" alt="" class="img-fluid"></a>
          </div>
          <div class="col-10">
            <a href="tg://resolve?domain=nik_gor86" class="cursor">Сотрудничество с нами</a>
          </div>
        </div>
        <p class="mt-3">Для представителей МФО, кредитных брокеров и вебмастеров</p>
        <p class="smtext">E-mail: <a href="mailto:work-zaim@yandex.ru">work-zaim@yandex.ru</a></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.nav-pills .nav-link.active{
  border-radius: 8px;
  border: 1px solid #787878;
  background: #FED305;
  color: #000;
}

.nav-link{
  border-radius: 8px;
  border: 1px solid #787878;
  background: #000;
  color: #fff;
}

a{
  color: #fff;
  text-decoration: none;
}

a:hover{
  color: #fff;
  text-decoration: underline;
}

.smtext{
  font-size: 14px;
}
</style>